import React from "react";
import { CpLoader } from "canopy-styleguide!sofe";

export default class ValidationFeedback extends React.Component {
  render() {
    return (
      <div
        className="cps-body-sm"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        Validating
        <CpLoader />
      </div>
    );
  }
}
