import React from "react";
import { isEmpty, partial, get, omit } from "lodash";
import { CpButton } from "canopy-styleguide!sofe";
import InputBuilder from "src/tests/input-builder.component.js";
import NamespaceBank from "src/namespace-bank.component.js";

import styles from "src/tests/create-edit-test.style.css";

export default class CreateEditTest extends React.PureComponent {
  state = {
    testData: this.props.test,
  };
  render() {
    return (
      <div className={styles.container}>
        {!isEmpty(this.props.namespaces) && (
          <NamespaceBank
            insertText={this.updateInputData}
            namespaces={this.props.namespaces}
          />
        )}
        <div className={styles.testForm}>
          {this.nameRow()}
          <div className="cps-margin-top-24" style={{ display: "flex" }}>
            <div className={styles.namespaceCol}>
              <InputBuilder
                inputData={this.state.testData.inputData}
                updateInputData={this.updateInputData}
                updateInputDataValue={this.updateInputDataValue}
                removeInputData={this.removeInputData}
              />
            </div>
            {this.outputCol()}
          </div>
        </div>
        {this.actions()}
      </div>
    );
  }

  nameRow = () => {
    return (
      <input
        type="text"
        className="cps-form-control"
        placeholder="Test name"
        value={this.state.testData.label}
        onChange={partial(this.handleInputChange, "label")}
      />
    );
  };

  handleInputChange = (field, e) => {
    const newVal = e.target.value;
    this.setState((prevState) => ({
      testData: {
        ...prevState.testData,
        [field]: newVal,
      },
    }));
  };

  updateInputDataValue = (inputData) => {
    this.setState((prevState) => ({
      testData: {
        ...prevState.testData,
        inputData,
      },
    }));
  };

  updateInputData = (newVal) => {
    this.setState((prevState) => {
      return !get(prevState.testData, `inputData.${newVal}`)
        ? {
            testData: {
              ...prevState.testData,
              inputData: {
                ...prevState.testData.inputData,
                [newVal]: "",
              },
            },
          }
        : {};
    });
  };

  removeInputData = (removedVal) => {
    this.setState((prevState) => {
      return {
        testData: {
          ...prevState.testData,
          inputData: {
            ...omit(prevState.testData.inputData, [removedVal]),
          },
        },
      };
    });
  };

  outputCol = () => {
    return (
      <div className={styles.outputCol}>
        <label htmlFor="expectedOutput">Expected output</label>
        <input
          className="cps-form-control"
          type="text"
          value={this.state.testData.expectedResult}
          placeholder="Expected output"
          onChange={partial(this.handleInputChange, "expectedResult")}
        />
      </div>
    );
  };

  actions = () => {
    return (
      <div className={styles.actions}>
        <CpButton
          onClick={partial(this.props.saveTest, this.state.testData)}
          disabled={
            isEmpty(this.state.testData.label) ||
            isEmpty(this.state.testData.inputData) ||
            isEmpty(this.state.testData.expectedResult)
          }
        >
          Done
        </CpButton>
        <CpButton btnType="flat" onClick={this.props.cancelTest}>
          Cancel
        </CpButton>
      </div>
    );
  };
}
