import React from "react";
import { CpTooltip } from "canopy-styleguide!sofe";

export default class ExpressionStatus extends React.PureComponent {
  render() {
    const { results } = this.props;

    return results && results.status === "error" ? (
      <CpTooltip text={getMessage(results)} interactive>
        <div
          className="cps-warning cps-caption cps-wt-bold"
          style={{
            textAlign: "right",
            display: "flex",
            alignItems: "center",
            marginLeft: 24,
          }}
        >
          Formula error <i className="cps-icon cps-icon-error cps-warning" />
        </div>
      </CpTooltip>
    ) : null;
  }
}

function getMessage(results) {
  const details =
    results.details && results.details.length
      ? `: <b>${results.details.join(", ")}</b>`
      : "";
  return `${results.message}${details}`;
}
