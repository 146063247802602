import React from "react";
import AsyncDecorator from "async-decorator/rx6";
import { get } from "lodash";
import { CpTabs, CpButton } from "canopy-styleguide!sofe";
import { catchAsyncStacktrace } from "auto-trace";
import { getTaxFormId, getVersionId } from "src/logic-editor.helper.js";

import TaskSelector from "./task-selector.component.js";
import LogicAlEmptyState from "./empty-state.component.js";
import TabularData from "./tabular-data.component.js";
import {
  convertDebugObjectToArrayOfObjects,
  formatValue,
  getDataBasedOnCurrentTab,
  getDataForTable,
  getRuleDebugData,
  getTaxPrepTask,
  SELECTED_TAX_FORM_TASK_KEY,
} from "./inspector.helper.js";
import { getRuleTestDebug as _getRuleTestDebug } from "./inspector-data.resource.js";

import styles from "./inspector.styles.css";

@AsyncDecorator
export default class Inspector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0,
      filteredDebug: [],
      filterString: "",
      fullDebugData: [],
      origDebugData: {},
      ruleDebugData: [],
      taxPrepTask: getTaxPrepTask(),
    };
  }

  componentDidMount() {
    if (this.state.taxPrepTask) {
      this.getRuleTestDebug();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uniqueNamespaces !== this.props.uniqueNamespaces) {
      this.getRuleTestDebug();
    }
  }

  render() {
    const {
      activeTabIndex,
      filterString,
      fullDebugData,
      origDebugData,
      taxPrepTask,
    } = this.state;
    const { currentVariable, detached, height } = this.props;

    return (
      <div style={{ height: `${height}px` }}>
        <div
          className={`cps-card cps-bg-color-background ${styles.keepBottomBorder}`}
        >
          <div
            className="cps-slat +noclick cps-bg-color-background"
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div className="cps-col-xs-6 cps-padding-right-8 cps-subheader-sm cps-wt-bold">
              Logic Assistant
              <div
                className="cps-link cps-margin-4 cps-margin-right-8 cps-color-monsoon"
                style={{
                  height: "30px",
                  padding: "0 4px 4px 4px",
                }}
              >
                <CpButton
                  icon="af-refresh"
                  aria-label="refresh"
                  onClick={this.getRuleTestDebug}
                />
              </div>
            </div>

            <div
              className="cps-col-xs-6 cps-padding-left-8"
              style={{
                alignItems: "center",
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end",
                textAlign: "end",
              }}
            >
              <div className="cps-padding-right-8 cps-color-monsoon">Task</div>
              <div style={{ flexGrow: 1 }}>
                <TaskSelector
                  key={get(taxPrepTask, "id", "foo")}
                  taxPrepTask={taxPrepTask}
                  setTaxPrepTask={this.setTaxPrepTask}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`cps-slat +noclick cps-bg-color-background ${styles.keepBottomBorder}`}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CpTabs activeId={activeTabIndex} onChange={this.handleTabChange}>
            <CpTabs.Button id={0}>This rule</CpTabs.Button>
            <CpTabs.Button id={1}>This form</CpTabs.Button>
          </CpTabs>
          <div className="cps-col-xs-3 cps-form-group cps-margin-bottom-0">
            <input
              className="cps-form-control"
              onChange={this.handleInputChange}
              placeholder="Filter..."
              ref={(el) => {
                this.varFilterEl = el;
              }}
              type="text"
              value={filterString}
            />
          </div>
        </div>
        <div className="cps-card cps-margin-8 cps-padding-8">
          {origDebugData && (
            <div
              className={`cps-card ${styles.keepBottomBorder} cps-padding-bottom-12`}
            >
              <span className="cps-body cps-wt-bold">Output:</span>{" "}
              {origDebugData.status === "error" ? (
                <span className="cps-body cps-red cps-wt-bold">Error</span>
              ) : (
                formatValue(origDebugData.output)
              )}
            </div>
          )}
          {taxPrepTask && fullDebugData.length ? (
            <div
              className={`cps-card cps-padding-top-12 ${styles.keepBottomBorder}`}
              style={{
                border: "none",
                height: `calc(${height}px - ${detached ? 0 : 230}px)`,
              }}
            >
              {origDebugData.status === "error" ? (
                <pre>{origDebugData.message}</pre>
              ) : (
                <TabularData
                  key={activeTabIndex}
                  currentVariable={currentVariable}
                  tabularData={getDataForTable(this.state)}
                  filterString={filterString}
                />
              )}
            </div>
          ) : (
            <LogicAlEmptyState />
          )}
        </div>
      </div>
    );
  }

  handleTabChange = (index) => {
    this.setState(() => ({ activeTabIndex: index }), this.filterDebug);
  };

  getRuleTestDebug = () => {
    const { cancelWhenUnmounted, name, rule, uniqueNamespaces } = this.props;
    const { taxPrepTask } = this.state;

    if (taxPrepTask && rule) {
      cancelWhenUnmounted(
        _getRuleTestDebug(
          taxPrepTask.client_id,
          taxPrepTask.resolution_case_id,
          getVersionId(),
          getTaxFormId(),
          name,
          rule
        ).subscribe((origDebugData) => {
          const fullDebugData = convertDebugObjectToArrayOfObjects(
            origDebugData.tabular_data
          );
          const ruleDebugData = getRuleDebugData(
            fullDebugData,
            uniqueNamespaces
          );

          this.setState(
            () => ({
              origDebugData,
              fullDebugData,
              ruleDebugData,
            }),
            this.filterDebug
          );
        }, catchAsyncStacktrace())
      );
    }
  };

  //Tax Prep Task Methods
  setTaxPrepTask = (taxPrepTask) => {
    this.setState(
      () => ({
        taxPrepTask,
      }),
      () => {
        if (this.props.rule) {
          this.getRuleTestDebug();
        }
        localStorage.setItem(
          SELECTED_TAX_FORM_TASK_KEY,
          JSON.stringify(taxPrepTask)
        );
      }
    );
  };

  // Filter Methods
  handleInputChange = (e) => {
    e.persist();
    this.setState(() => ({ filterString: e.target.value }), this.filterDebug);
  };

  clearFilterString = () => {
    this.setState(() => ({ filterString: "" }), this.filterDebug);
  };

  filterDebug = () => {
    const { filterString = "" } = this.state;

    const tabularData = getDataBasedOnCurrentTab(this.state);

    this.setState(() => ({
      filteredDebug: filterString.length
        ? tabularData.reduce((accumulator, item) => {
            if (
              item.key.toLowerCase().includes(filterString.toLowerCase()) ||
              formatValue(item.val)
                .toString()
                .toLowerCase()
                .includes(filterString.toLowerCase())
            ) {
              accumulator.push(item);
            }

            return accumulator;
          }, [])
        : [],
    }));
  };
}
